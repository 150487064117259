import api from "@/services/api";

export default {
    checkConnection() {
        return api.get('is-alive/')
            .then(function () {
                return true;
            })
            .catch(function () {
                // eslint-disable-next-line
                console.log('Could not connect to API')
                return false;
            });
    }
};
