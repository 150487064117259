<template>
    <tile :position="position" no-fade>
        <span>{{ this.title }}</span>
        <ul class="text-left text-base leading-tight p-4">
            <li class="py-1" v-bind:class="{ 'text-gray-500': dayOfWeek != 1 }">Mandag: 8.30 - 14.00</li>
            <li class="py-1" v-bind:class="{ 'text-gray-500': dayOfWeek != 2 }">Tirsdag: 8.30 - 13.15</li>
            <li class="py-1" v-bind:class="{ 'text-gray-500': dayOfWeek != 3 }">Onsdag: 8.30 - 12.30</li>
            <li class="py-1" v-bind:class="{ 'text-gray-500': dayOfWeek != 4 }">Torsdag: 8.30 - 13.15</li>
            <li class="py-1" v-bind:class="{ 'text-gray-500': dayOfWeek != 5 }">Fredag: 8.30 - 14.00</li>
        </ul>
    </tile>
</template>
<script>
import moment from "moment-timezone";
import Tile from './Tile';

export default {
    components: {
        Tile,
    },

    props: {
        dateFormat: {
            type: String,
            default: "E"
        },
        locale: {
            type: String,
            default: "en"
        },
        position: {
            type: String
        },
        timeZone: {
            type: String
        },
        title: {
            type: String,
            default: "Schedule"
        }
    },

    data() {
        return {
            dayOfWeek: ""
        };
    },

    created() {
        this.refreshTime();
        setInterval(this.refreshTime, 60000); // every minute
    },

    methods: {
        refreshTime() {
            this.dayOfWeek = moment()
                .locale(this.locale)
                .tz(this.timeZone)
                .format(this.dateFormat);
            }
        }
    };
</script>
