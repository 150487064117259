<template>
    <tile :position="position" no-fade>
        <h1>{{ title }}</h1>
        <vc-donut v-bind:class="blink(blackDaysLeft)" class="m-4" :sections="blackSection" :size=size unit="px" :thickness=thickness>
            <h1 class="text-3xl text-color-black">{{ blackDaysLeft }}</h1>
        </vc-donut>
        <vc-donut v-bind:class="blink(greenDaysLeft)" class="m-4" :sections="greenSection" :size=size unit="px" :thickness=thickness>
            <h1 class="text-3xl text-color-green">{{ greenDaysLeft }}</h1>
        </vc-donut>
        <vc-donut v-bind:class="blink(orangeDaysLeft)" class="m-4" :sections="orangeSection" :size=size unit="px" :thickness=thickness>
            <h1 class="text-3xl text-color-orange">{{ orangeDaysLeft }}</h1>
        </vc-donut>
    </tile>
</template>
<script>
import recycleSchedule from "../services/recycleSchedule";
import Tile from './Tile';

export default {
    components: {
        Tile,
    },

    props: {
        position: {
            type: String,
        },
        title: {
            type: String,
            default: "Schedule"
        },
    },

    computed: {
        blackSection: function () {
            return [{ value: this.blackPercent, color: this.blackColor }]
        },
        greenSection: function () {
            return [{ value: this.greenPercent, color: this.greenColor }]
        },
        orangeSection: function () {
            return [{ value: this.orangePercent, color: this.orangeColor  }]
        },
        blackBlink: function () {
            return this.blackDaysLeft < 2 ? true : false;
        }
    },

    data() {
        return {
            size: 70,
            thickness: 25,

            blackColor: '#0f0214',
            blackDaysLeft:  0,
            blackPercent:  0,

            greenColor: '#2dbd2d',
            greenDaysLeft:  0,
            greenPercent:  0,

            orangeColor: '#f27f52',
            orangeDaysLeft:  0,
            orangePercent:  0,
        };
    },

    created() {
        this.fetchSchedule();
        setInterval(this.fetchSchedule, 60 * 60 * 1000); // every hour
    },

    methods: {
        blink(daysLeft) {
            return daysLeft < 2 ? 'blink' : '';
        },
        async fetchSchedule() {
            const schedule = await recycleSchedule.fetchRecycleSchedule();

            if (schedule.black) {
                this.blackDaysLeft = schedule.black.daysLeft;
                this.blackPercent = schedule.black.percent;
            } else {
                this.blackDaysLeft = '-';
            }

            if (schedule.green) {
                this.greenDaysLeft = schedule.green.daysLeft;
                this.greenPercent = schedule.green.percent;
            } else {
                this.greenDaysLeft = '-';
            }

            if (schedule.orange) {
                this.orangeDaysLeft = schedule.orange.daysLeft;
                this.orangePercent = schedule.orange.percent;
            } else {
                this.orangeDaysLeft = '-';
            }
        },
    }
};
</script>

<style scoped>
.text-color-black {
    color: #0f0214;
}

.text-color-green {
    color: #2dbd2d;
}

.text-color-orange {
    color: #f27f52;
}

.blink {
  animation: blinker 4s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

</style>
