<template>
    <tile :position="position" no-fade>
        <span>{{ this.title }}</span>
        <ul class="text-left text-base leading-tight p-4">
            <li class="py-1" v-for="d in schedule" :key="d.index">
                <span :class="{ 'text-gray-500' : d.highlight != true}">{{ d.day|capitalize }}: {{ d.text }}</span>
            </li>
        </ul>
    </tile>
</template>
<script>
import moment from "moment-timezone";
import workSchedule from "../services/workSchedule";
import Tile from './Tile';

export default {
    components: {
        Tile,
    },

    filters: {
        capitalize: function (value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        }
    },

    props: {
        dateFormat: {
            type: String,
            default: "dddd"
        },
        locale: {
            type: String,
            default: "en"
        },
        position: {
            type: String
        },
        timeZone: {
            type: String
        },
        title: {
            type: String,
            default: "Schedule"
        }
    },

    data() {
        return {
            schedule: [],
        };
    },

    created() {
        this.fetchSchedule();
        setInterval(this.fetchSchedule, 60000 * 60); // every hour
    },

    methods: {

        async fetchSchedule() {
            const schedule = await workSchedule.fetchSchedule();
            const result = []

            for (const [i, d] of schedule.entries()) {
                var h = moment().isSame(moment(d['day']), 'day');
                var s = moment(d['day'])
                    .locale(this.locale)
                    .tz(this.timeZone)
                    .format(this.dateFormat);
                    result.push({'index': i, 'day': s, 'highlight': h, 'text': d['text']})
            }

            this.schedule = result
        },


    }
};
</script>
