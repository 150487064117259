<template>
    <div class="dashboard">
        <div class="grid-container">
            <!-- List components -->
            <connected position="5 / 1 / span 1 / span 1"></connected>
            <time-weather time-zone="Europe/Oslo" locale="nb" position="1 / 7 / span 2 / span 2"></time-weather>
            <school-schedule time-zone="Europe/Oslo" locale="nb" position="1 / 2 / span 3 / span 2" title="Tobias"></school-schedule>
            <recycle-schedule time-zone="Europe/Oslo" title="Avfall" position="1 / 1 / span 4 / span 1"></recycle-schedule>
            <work-schedule time-zone="Europe/Oslo" locale="nb" title="Mamma" position="1 / 4 / span 3 / span 2"></work-schedule>

            <football-schedule time-zone="Europe/Oslo" locale="nb" title="Liverpool" position="4 / 2 / span 3 / span 4"></football-schedule>
        </div>
    </div>
</template>
<script>
import Connected from "@/components/Connected";
import FootballSchedule from "@/components/FootballSchedule";
import RecycleSchedule from "@/components/RecycleSchedule";
import SchoolSchedule from "@/components/SchoolSchedule";
import TimeWeather from "@/components/TimeWeather";
import WorkSchedule from "@/components/WorkSchedule";

export default {
    components: {
        Connected,
        FootballSchedule,
        RecycleSchedule,
        SchoolSchedule,
        TimeWeather,
        WorkSchedule,
    }
};
</script>
