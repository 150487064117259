<template>
    <tile :position="position" no-fade>
        <span>{{ this.title }}</span>
        <ul class="text-left text-base leading-tight p-4">
            <li class="py-1" v-for="d in schedule.finished" :key="d.index">
                <span class="text-gray-500">{{ d.date | formatDate }}: {{ d.homeTeam }} - {{ d.awayTeam }} - {{ d.homeTeamScore }} - {{ d.awayTeamScore }}</span>
            </li>
            <li class="py-1" v-for="(d, i) in schedule.scheduled" :key="d.index">
                <span :class="{ 'text-gray-500' : 0 != i}">{{ d.date | formatDate }}: {{ d.homeTeam }} - {{ d.awayTeam }}</span>
            </li>
        </ul>
    </tile>
</template>
<script>
import moment from "moment-timezone";
import footballSchedule from "../services/footballSchedule";
import Tile from './Tile';

export default {
    components: {
        Tile,
    },

    filters: {
        formatDate: function (value) {
            var s = moment(value)
                .format("DD.MM - HH:mm");
            return s
        },
    },

    props: {
        locale: {
            type: String,
            default: "en"
        },
        position: {
            type: String
        },
        timeZone: {
            type: String
        },
        title: {
            type: String,
            default: "Football"
        }
    },

    data() {
        return {
            schedule: [],
        };
    },

    created() {
        this.fetchSchedule();
        setInterval(this.fetchSchedule, 60000 * 60); // every hour
    },

    methods: {
        async fetchSchedule() {
            const schedule = await footballSchedule.fetchSchedule();
            this.schedule = schedule
        },
    }
};
</script>
