require("./main.scss");

import Vue from "vue";
import App from "@/App.vue";

import store from "@/store";
import router from "@/router";

Vue.config.productionTip = false;

import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";
Vue.use(Donut);

// Vue.use(VueRouter)

const vue = new Vue({
    router,
    store,
    render: h => h(App)
});

vue.$mount("#app");
