<template>
    <tile :position="position" no-fade>
        <h1 class="text- uppercase">{{ date }}</h1>
        <p class="text-5xl time">{{ time }}</p>

        <!--  
        <span>
            32°
            <span class="text-sm uppercase text-dimmed">ute</span>
        </span>
        <span>
            33
            <span class="text-sm uppercase text-dimmed">inne</span>
        </span>
        -->
    </tile>
</template>
<script>
import moment from "moment-timezone";
import Tile from './Tile';

// todo Add wather and indoor temperature
// import weather from "../services/weather/Weather";
// import netatmo from "../services/netatmo/Netatmo";

export default {
    components: {
        Tile,
    },

    props: {
        dateFormat: {
            type: String,
            default: "dddd DD.MM.YYYY"
        },
        locale: {
            type: String,
            default: "en"
        },
        position: {
            type: String
        },
        timeFormat: {
            type: String,
            default: "HH:mm"
        },
        timeZone: {
            type: String
        }
    },

    data() {
        return {
            date: "",
            time: ""
        };
    },

    created() {
        this.refreshTime();
        setInterval(this.refreshTime, 1000);
    },

    methods: {
        refreshTime() {
            this.date = moment()
                .locale(this.locale)
                .tz(this.timeZone)
                .format(this.dateFormat);
            this.time = moment()
                .tz(this.timeZone)
                .format(this.timeFormat);
        }
    }
};
</script>
