<template>
    <div :style="tilePosition" class="overflow-hidden">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        position: {
            type: String,
        },
    },

    computed: {
        tilePosition() {
            var style = 'grid-area: ' + this.position
            return style;
        },
    },

    methods: {
    },
};
</script>
