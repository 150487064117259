<template>
    <tile :position="position">
        <span class="fa-stack fa-1x">
            <i class="fas fa-wifi fa-stack-1x"></i>
            <i v-if="!this.isConnected" class="fas fa-ban fa-stack-1x" style="color:Tomato"></i>
        </span>

    </tile>
</template>
<script>
import checkConnection from "../services/checkConnection";
import Tile from './Tile';

export default {
    components: {
        Tile,
    },

    props: {
        position: {
            type: String,
        },
    },

    data() {
        return {
            isConnected: false,
        };
    },

    created() {
        this.checkConnection();
        setInterval(this.checkConnection, 1000 * 30 ); // every 30 second
    },

    methods: {
        async checkConnection() {
            this.isConnected = await checkConnection.checkConnection();
        },
    }
};
</script>
